<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">General</h2>
      <form @submit.prevent="save">
        <FormComponent
        :can-edit="canEdit"
        :key="this.componentKey"
        :is-loading="this.isLoading"
        :init-categories-value="this.initValue"
        :categories_="this.categories"
        :products="this.products"
        :form-data_="this.formData"
        @update:model="updateFormData"
        />
        <SubmitMainButton v-if="canEdit" :is-loading="isLoading" :show-cancel-button="true" :click-handler="forceRerender" />
    </form>
    </div>
  </div>  
</template>

<script>
import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

import FormComponent from '@/partials/products/FormComponent.vue';
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'GeneralPanel',
  props: {
    isLoading: Boolean,
    categories: Array,
    product: Object,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  components: {
    FormComponent,
    SubmitMainButton,
  },
  data(){
    return {
      componentKey: 0,
      products: [],
      initValue: this.categories.findIndex(c => c.name == this.product.category),
      category: {
          name: null
      },
      formData: this.product,
    }
  },
  methods: {
    async forceRerender() {
      const productId = this.$route.params.id;
      await loadFromLocalStorage('productsEdit', 'products', this);

      const temp = this.products.find(product => product.id === productId) || {};
      this.formData = temp;
      this.componentKey += 1;
      this.$emit('forceRender');
    },
    save(){
      this.$emit('save', this.formData);
    },
    updateFormData(updated){
      Object.assign(this.formData, updated);
    },
  },
  async created(){
    const productId = this.$route.params.id;
    
    await loadFromLocalStorage('productsEdit', 'products', this);
    this.products = this.products.filter((product) => product.id !== productId)
    this.componentKey += 1;
  }
}
</script>