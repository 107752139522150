<template>
    <ExtrasAccordion class="sm:col-span-6" :title="'Parametros Avanzados Para Prospeccion'">
        <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
            <div v-for="(item, index) in configList" :key="index" :class="item.type === 'input' ? 'col-span-3 lg:col-span-1' : 'col-span-3 lg:col-span-2'">
                <label class="block text-sm font-medium mb-1">{{ $t(item.label) }}:</label>
                <input v-if="item.type === 'input'"
                    class="form-input w-full"
                    :type="item.inputType"
                    :step="item.step"
                    :placeholder="item.placeholder"
                    v-model="formData[item.model]"
                    :disabled="!canEdit || (item.naModel ? formData[item.naModel] : false)"
                />

                <div v-if="item.naModel" class="form-check">
                    <input
                        :disabled="!canEdit"
                        class="form-checkbox"
                        type="checkbox"
                        v-model="formData[item.naModel]"
                        @change="callChangeMethod(item)">
                    
                    <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">NA</span>
                </div>
                <DropdownFull v-if="item.type === 'dropdown'"
                    :label="item.label"
                    :disable-inputs="!canEdit"
                    :init-value="item.initValue"
                    :dropdownOptions="item.dropdownOptions"
                    @update:model="item.updateMethod($event, item.propName, item.updatedPropName)"
                />
            </div>  
            <div class="sm:col-span-3" >
                <label class="block text-sm font-medium mb-1">
                    {{ $t('labels.precandidate') }}:
                </label>
                <div class="w-full">
                    <span v-if="formData.precandidate">
                        <DropdownFull 
                        :disable-inputs="!canEdit"
                        :init-value="initPrecandidateValue !== -1 ? initPrecandidateValue : 0"
                        :dropdownOptions="this.products" 
                        @update:model="updatePrecandidateProductId"/>
                    </span>
                    <span v-else>
                        <input class="form-input w-full" type="text" placeholder="Selecciona el producto de prospeccion" :disabled="true" />
                    </span>
                    <input class="form-checkbox" type="checkbox" 
                    :disabled="!canEdit"
                    @change="changePrecandidate"
                    v-model="formData.precandidate" />
                    <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Precandidato</span>
                </div>
                <div>
                    <input class="form-checkbox" type="checkbox" 
                    :disabled="!canEdit"
                    v-model="formData.excludeFromProspection" />
                    <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{{ $t('labels.excludeFromProspection') }}</span>
                </div>
            </div>                 
        </div>
    </ExtrasAccordion>
</template>

<script>
    import DropdownFull from '@/components/DropdownFull.vue';
    import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
    
    export default {
        name: 'ExtrasProspectionAccordion',
        components: {
            DropdownFull,
            ExtrasAccordion,
        },
        props:{
            canEdit: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
            products: Array,
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function() {
                    return {
                        name: '',
                        description: '',
                        maximumAge: null,
                        maximumAgeNA: true,
                        minimumInfonavitPoints: null,
                        minimumInfonavitPointsNA: true,
                        maximumInfonavitPoints: null,
                        maximumInfonavitPointsNA: true,
                        minimumDiscountedWeeks: null,
                        minimumDiscountedWeeksNA: true,
                        maxmumDiscountedWeeks: null,
                        maxmumDiscountedWeeksNA: true,
                        maximumDaysInactive: null,
                        maximumDaysInactiveNA: true,
                        fractionMaximumQuotedWeeksInactive: null,
                        fractionMaximumQuotedWeeksInactiveNA: true,
                        maximumLastSalary: null,
                        maximumLastSalaryNA: true,
                        activeBefore1982: null,
                        canVisualizeQuotedWeeksReport: null,
                        priority: null,
                        precandidateProductId: null,
                        precandidate: false,
                        minimumAge: null,
                        minimumAgeNA: true,
                        minimumBalance: null,
                        minimumBalanceNA: true,
                        maximumBalance: null,
                        maximumBalanceNA: true,
                        canHaveCredit: null,
                        creditMustNotBeActive: null,
                        mustHaveCredit: null,
                        isActive: null,
                        minimumWeeks: null,
                        minimumWeeksNA: true,
                        maximumWeeks: null,
                        maximumWeeksNA: true,
                        isLaw73: null,
                        minimumDaysInactive: null,
                        minimumDaysInactiveNA: true,
                        category: null,
                        canHaveCredit: null,
                        excludeFromProspection: false
                    };
                }
            }
        },
        data() {
            const canHaveCreditOptions = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'Puede Tener', value: true },
                { id: 2, name: 'No puede de Tener', value: false },
            ]
            const creditMustNotBeActiveOptions = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'El credito debe estar vigente', value: true },
                { id: 2, name: 'El credito no debe estar vigente', value: false },
            ]
            const mustHaveCreditOptions = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'Debe de tener Credito', value: true },
                { id: 2, name: 'No debe de tener credito', value: false },
            ]
            const isActiveOptions = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'Vigente en el IMSS', value: true },
                { id: 2, name: 'Inactivo en el IMSS', value: false },
            ]
            const isLaw73Options = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'Ley 73', value: true },
                { id: 2, name: 'Ley 97', value: false },
            ]
            const yesNoOptions = [
                { id: 0, name: 'No aplica', value: null },
                { id: 1, name: 'Si', value: true },
                { id: 2, name: 'No', value: false },
            ]
            return {
                initPrecandidateValue: this.products.findIndex(c => c.id == this.formData_.precandidateProductId),
                formData: this.formData_,
                configList: [
                    { label: 'labels.priority', model: 'priority', type: 'input', inputType: 'number', step: 1, placeholder: '60' },
                    { label: 'labels.minimumAge', model: 'minimumAge', type: 'input', inputType: 'number', step: 1, placeholder: '60', naModel: 'minimumAgeNA', naChangeMethod: 'changePropertyNa', naPropName: 'minimumAge' },
                    { label: 'labels.maximumAge', model: 'maximumAge', type: 'input', inputType: 'number', step: 1, placeholder: '60', naModel: 'maximumAgeNA', naChangeMethod: 'changePropertyNa', naPropName: 'maximumAge' },
                    { label: 'labels.minimumBalance', model: 'minimumBalance', type: 'input', inputType: 'number', step: 0.001, placeholder: '0.00', naModel: 'minimumBalanceNA', naChangeMethod: 'changePropertyNa', naPropName: 'minimumBalance' },
                    { label: 'labels.maximumBalance', model: 'maximumBalance', type: 'input', inputType: 'number', step: 0.001, placeholder: '0.00', naModel: 'maximumBalanceNA', naChangeMethod: 'changePropertyNa', naPropName: 'maximumBalance' },
                    { label: 'labels.minimumInfonavitPoints', model: 'minimumInfonavitPoints', type: 'input', inputType: 'number', step: 1, placeholder: '60', naModel: 'minimumInfonavitPointsNA', naChangeMethod: 'changePropertyNa', naPropName: 'minimumInfonavitPoints' },
                    { label: 'labels.maximumInfonavitPoints', model: 'maximumInfonavitPoints', type: 'input', inputType: 'number', step: 1, placeholder: '60', naModel: 'maximumInfonavitPointsNA', naChangeMethod: 'changePropertyNa', naPropName: 'maximumInfonavitPoints' },
                    { label: 'labels.maximumLastSalary', model: 'maximumLastSalary', type: 'input', inputType: 'number', step: 1, placeholder: '60', naModel: 'maximumLastSalaryNA', naChangeMethod: 'changePropertyNa', naPropName: 'maximumLastSalary' },
                    { label: 'labels.minimumDaysInactive', model: 'minimumDaysInactive', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'minimumDaysInactiveNA', naChangeMethod: 'changePropertyNa', naPropName: 'minimumDaysInactive' },
                    { label: 'labels.maximumDaysInactive', model: 'maximumDaysInactive', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'maximumDaysInactiveNA', naChangeMethod: 'changeMaximumDaysInactiveNA' },
                    { label: 'labels.fractionMaximumQuotedWeeksInactive', model: 'fractionMaximumQuotedWeeksInactive', type: 'input', inputType: 'number', step: 0.01, placeholder: '0.5', naModel: 'fractionMaximumQuotedWeeksInactiveNA', naChangeMethod: 'changeFractionMaximumQuotedWeeksInactiveNA' },
                    { label: 'labels.minimumWeeks', model: 'minimumWeeks', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'minimumWeeksNA', naChangeMethod: 'changePropertyNa',naPropName: 'minimumWeeks' },
                    { label: 'labels.maximumWeeks', model: 'maximumWeeks', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'maximumWeeksNA', naChangeMethod: 'changePropertyNa',naPropName: 'maximumWeeks' },
                    { label: 'labels.minimumDiscountedWeeks', model: 'minimumDiscountedWeeks', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'minimumDiscountedWeeksNA', naChangeMethod: 'changePropertyNa', naPropName: 'minimumDiscountedWeeks' },
                    { label: 'labels.maximumDiscountedWeeks', model: 'maximumDiscountedWeeks', type: 'input', inputType: 'number', step: 1, placeholder: '1', naModel: 'maximumDiscountedWeeksNA', naChangeMethod: 'changePropertyNa', naPropName: 'maximumDiscountedWeeks' },
                    { label: 'labels.canHaveCredit', model: 'canHaveCredit', type: 'dropdown', dropdownOptions: canHaveCreditOptions, initValue: canHaveCreditOptions.findIndex(c => c.value == this.formData_.canHaveCredit), updateMethod: this.updateDropdown, propName: 'canHaveCredit', updatedPropName: 'value'},
                    // TODO: precandidate make !precandidate
                    { label: 'labels.creditMustNotBeActive', model: 'creditMustNotBeActive', type: 'dropdown', dropdownOptions: creditMustNotBeActiveOptions, initValue: creditMustNotBeActiveOptions.findIndex(c => c.value == this.formData_.creditMustNotBeActive), updateMethod: this.updateDropdown, propName: 'creditMustNotBeActive', updatedPropName: 'value' },
                    { label: 'labels.mustHaveCredit', model: 'mustHaveCredit', type: 'dropdown', dropdownOptions: mustHaveCreditOptions, initValue: mustHaveCreditOptions.findIndex(c => c.value == this.formData_.mustHaveCredit), updateMethod: this.updateDropdown, propName: 'mustHaveCredit', updatedPropName: 'value' },
                    { label: 'labels.isActive', model: 'isActive', type: 'dropdown', dropdownOptions: isActiveOptions, initValue: isActiveOptions.findIndex(c => c.value == this.formData_.isActive), updateMethod: this.updateDropdown, propName: 'isActive', updatedPropName: 'value' },
                    { label: 'labels.activeBefore1982', model: 'activeBefore1982', type: 'dropdown', dropdownOptions: yesNoOptions, initValue: yesNoOptions.findIndex(c => c.value == this.formData_.activeBefore1982), updateMethod: this.updateDropdown, propName: 'activeBefore1982', updatedPropName: 'value'},
                    { label: 'labels.isLaw73', model: 'isLaw73', type: 'dropdown', dropdownOptions: isLaw73Options, initValue: isLaw73Options.findIndex(c => c.value == this.formData_.isLaw73), updateMethod: this.updateDropdown, propName: 'isLay73', updatedPropName: 'value' },
                    { label: 'labels.canVisualizeQuotedWeeksReport', model: 'canVisualizeQuotedWeeksReport', type: 'dropdown', dropdownOptions: yesNoOptions, initValue: yesNoOptions.findIndex(c => c.value == this.formData_.canVisualizeQuotedWeeksReport), updateMethod: this.updateDropdown, propName: 'canVisualizeQuotedWeeksReport', updatedPropName: 'value' },
                ],
            };
        },
        methods: {
            callChangeMethod(item) {
                if (item.naChangeMethod && typeof this[item.naChangeMethod] === 'function') {
                    this[item.naChangeMethod](item.naPropName);
                }
            },
            changePropertyNa(propName) {
                this.formData[propName] = null;
                this.$emit('update:model', this.formData);
            },
            changePrecandidate(){
                this.formData.precandidateProductId = null;
                // this.formData.precandidate = !this.formData.precandidate;
                this.$emit('update:model', this.formData);
            },
            changeMaximumDaysInactiveNA(){
                this.formData.maximumDaysInactive = null; 
                this.formData.fractionMaximumQuotedWeeksInactive = null; 
                this.formData.fractionMaximumQuotedWeeksInactiveNA = true;
                this.$emit('update:model', this.formData);
            },
            changeFractionMaximumQuotedWeeksInactiveNA(){
                this.formData.maximumDaysInactive = null; 
                this.formData.fractionMaximumQuotedWeeksInactive = null; 
                this.formData.maximumDaysInactiveNA = true;
                this.$emit('update:model', this.formData);
            },
            
            updateDropdown(updated, propName, updatedPropName){
                this.formData[propName] = updated[updatedPropName]
                this.$emit('update:model', this.formData);
            },
            updatePrecandidateProductId(updated){
                this.formData.precandidateProductId = updated.id;
                this.$emit('update:model', this.formData);
            },
        },
        created(){
            this.formData.minimumAgeNA =  this.formData_.minimumAge === null
            this.formData.maximumAgeNA = this.formData_.maximumAge === null
            this.formData.minimumBalanceNA = this.formData_.minimumBalance === null
            this.formData.maximumBalanceNA = this.formData_.maximumBalance === null
            this.formData.minimumInfonavitPointsNA = this.formData_.minimumInfonavitPoints === null
            this.formData.maximumInfonavitPointsNA = this.formData_.maximumInfonavitPoints === null
            this.formData.minimumWeeksNA = this.formData_.minimumWeeks === null
            this.formData.maximumWeeksNA = this.formData_.maximumWeeks === null
            this.formData.minimumDiscountedWeeksNA = this.formData_.minimumDiscountedWeeks === null
            this.formData.maximumDiscountedWeeksNA = this.formData_.maximumDiscountedWeeks === null
            this.formData.minimumDaysInactiveNA = this.formData_.minimumDaysInactive === null
            this.formData.maximumDaysInactiveNA = this.formData_.maximumDaysInactive === null
            this.formData.fractionMaximumQuotedWeeksInactiveNA = this.formData_.fractionMaximumQuotedWeeksInactive === null
            this.formData.maximumLastSalaryNA = this.formData_.maximumLastSalary === null
        }
    }
</script>